import Header from './Header'
import Footer from './Footer'
import { Outlet, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Basic = (props) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [removeFooterIos, setRemoveFooterIos] = useState()

    useEffect(() => {
        setRemoveFooterIos(searchParams.get('ios'))
    }, [])
    return(
        <div className="app-container relative">
            {
                searchParams.get('mobile') != 'true'?
                <div className="header-container shadow-lg">
                    <Header showLogin={props.showLogin ?? true} languageSelector={props.languageSelector ?? false}></Header>
                </div>:
                <></>
            }
           
            <div>
                <div className={`${searchParams.get('mobile') == 'true'? "main-mobile-container":"main-container"} relative`}>
                    <Outlet></Outlet>
                </div>
                {props.children}
                <div className="footer-container z-0">
                    {
                        removeFooterIos || searchParams.get('mobile') == 'true' ? 
                        <></>
                        :
                        <Footer></Footer>
                    }
                </div>
            </div>
      </div>
    )
}
export default Basic