import { Helmet } from "react-helmet"
import $ from 'jquery'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
 
const LayoutV2 = (props) => {
    const location = useLocation()
    const navigate = useNavigate()
    useEffect(() => {
        const token = localStorage.getItem('token')
        if(token === null){
            navigate("/community/admin/login",{ replace: false })
        }
        const sidebarToggle = document.body.querySelector('#sidebarToggle');
        if (sidebarToggle) {
            sidebarToggle.addEventListener('click', event => {
                event.preventDefault();
                document.body.classList.toggle('sb-sidenav-toggled');
                localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
            });
        }
    }, [])

    function logout(){
        navigate("/community/admin/login",{ replace: false })
        localStorage.removeItem("token")
        localStorage.removeItem("community_id")
        localStorage.removeItem("community_code")
        localStorage.removeItem("community_role")
    }
    return (
        <>
        <Helmet>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/startbootstrap-simple-sidebar@6.0.6/dist/css/styles.css" />
            <link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/1.13.1/css/jquery.dataTables.css"/>
            <link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/buttons/2.3.2/css/buttons.dataTables.min.css"/>
            <script nonce="causeidwebsite" src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
        </Helmet>
        <div className="d-flex" id="wrapper">
            <div className="border-end bg-white" id="sidebar-wrapper">
                <div>
                    <div className="sidebar-heading border-bottom bg-light">
                        <Link to='/'>
                            <div className="logo">
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="list-group list-group-flush">
                    <Link to='/community/admin/dashboard' className={`list-group-item list-group-item-action list-group-item-light p-3 ${location.pathname === '/community/admin/dashboard' ? 'active' : ''}`}>
                        Dashboard
                    </Link>
                    <Link to='/community/community-event' className={`list-group-item list-group-item-action list-group-item-light p-3 ${location.pathname === '/community/community-event' ? 'active' : ''}`}>
                        Event
                    </Link>
                    <Link to='/community/community-info' className={`list-group-item list-group-item-action list-group-item-light p-3 ${location.pathname === '/community/community-info' ? 'active' : ''}`}>
                        Basic Info
                    </Link>
                    <Link to='/community/community-logo' className={`list-group-item list-group-item-action list-group-item-light p-3 ${location.pathname === '/community/community-logo' ? 'active' : ''}`}>
                        Logo
                    </Link>
                    <Link to='/community/community-member' className={`list-group-item list-group-item-action list-group-item-light p-3 ${location.pathname === '/community/community-member' ? 'active' : ''}`}>
                        Member
                    </Link>
                    <Link to='/community/community-rewards' className={`list-group-item list-group-item-action list-group-item-light p-3 ${location.pathname === '/community/community-rewards' ? 'active' : ''}`}>
                        Rewards
                    </Link>
                    <Link to='/community/community-redeem' className={`list-group-item list-group-item-action list-group-item-light p-3 ${location.pathname === '/community/community-redeem' ? 'active' : ''}`}>
                        Redeem
                    </Link>
                    <Link to='/community/community-activities' className={`list-group-item list-group-item-action list-group-item-light p-3 ${location.pathname === '/community/community-activities' || location.pathname === '/community/community-activities/details'  ? 'active' : ''}`}>
                        Activities
                    </Link>
                </div>
            </div>
            <div id="page-content-wrapper">
                <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom" style={{ padding: "0.7rem" }}>
                    <div className="container-fluid">
                        <button className="btn btn-primary" id="sidebarToggle"><FontAwesomeIcon className="align-middle" icon="bars" /></button>
                        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button> */}
                        {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                                <li className="nav-item active"><a className="nav-link" href="#!">Home</a></li>
                                <li className="nav-item"><a className="nav-link" href="#!">Link</a></li>
                            </ul>
                        </div> */}
                        <form class="form-inline ">
                            {/* <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button> */}
                            {
                                localStorage.getItem('token')!= null ? 
                                <button onClick={logout} style={{ width: "120px" }}
                                    className="font-semibold"
                                >
                                    Log Out
                                </button>:''
                            }
                        </form>
                    </div>
                </nav>
                {props.children}
            </div>
        </div>
        </>
    )
}


export default LayoutV2
